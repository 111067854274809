import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image";

const Banner = (props) => {
    const bannerInternalPageQueryData = useStaticQuery (graphql`
        query BannerInternalPageQuery {
  
            file(relativePath: {eq: "images/banner/Marie-Luce-Beraudo-photo.jpg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);
      
    const PortfolioImages = bannerInternalPageQueryData.file.childImageSharp.fixed;

    return (
        <div className="rn-slider-area" id="home">
            {/* Start Single Slider  */}
            <div className="rn-slide slider-style-01 seo-services">
                <div className="wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="inner">
                                    <div className="content">
                                    <h1 className="title white-color">{props.title}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner">
                                    <div className="content text-center">

                                        <div className="thumbnail">
                                            <Img className="portfolio-images" fixed={PortfolioImages} alt="Marie-Luce Beraudo - SEO Alternative" />
                                        </div>  
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Single Slider  */}
        </div>
    )
}
export default Banner;