import React from "react";
import Layout from "../components/layout";
import Content from "../components/mentions/content";
import Banner from "../components/banner";
import Seo from "../components/seo";




const SeoServices = () => (
    <Layout>
        <Seo title="Marie-Luce Beraudo, spécialiste SEO dans le pays de Gex et à Genève" />

        <Banner title="Mentions légales"></Banner>
        <Content></Content>
    </Layout>

)
export default SeoServices;