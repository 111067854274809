import React from 'react';


const Content = () => {


    return (

        <div className="blog-details-wrapper pt--70 pb--100 bg-color-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">

               
                        <div className="post-content">
                        <h2 className="post-title">Propri&eacute;t&eacute; de SEO Alternative</h2>
<p>Le site Web seoalternative.fr a &eacute;t&eacute; d&eacute;velopp&eacute; par Marie-Luce Beraudo, consultante Web sp&eacute;cialiste SEO.</p>
<p>N&deg; SIRET : 49772787500021</p>
<p>Si&egrave;ge social :<br />215 chemin du Peillon<br />01170 Crozet<br />France</p>
<p>Num&eacute;ro de t&eacute;l&eacute;phone : 07 86 46 36 81</p>
<p>H&eacute;bergeur :<br />Netlify<br />610 22nd Street<br />Suite 315 CA <br />94107 San Francisco <br />+1 844-899-7312</p>
<h2 className="post-title">Gestion des donn&eacute;es personnelles</h2>
<p>Le site Web seoalternative est susceptible de collecter vos donn&eacute;es personnelles lorsque vous remplissez le formulaire de contact.</p>
<p>Les informations recueillies sur ce formulaire sont enregistr&eacute;es dans un fichier informatis&eacute; par Marie-Luce Beraudo<strong>&nbsp;</strong>uniquement dans le but de g&eacute;rer sa relation commerciale avec ses clients et prospects.&nbsp;</p>
<p>Les donn&eacute;es collect&eacute;es ne seront pas communiqu&eacute;es &agrave; des personnes physiques ou morales autres que Marie-Luce Beraudo.</p>
<p>Les donn&eacute;es sont conserv&eacute;es pendant 3 ans.</p>
<p>Vous pouvez acc&eacute;der aux donn&eacute;es vous concernant, les rectifier, demander leur effacement ou exercer votre droit &agrave; la limitation du traitement de vos donn&eacute;es.&nbsp;</p>
<p>Consultez le site&nbsp;cnil.fr pour plus d&rsquo;informations sur vos droits.</p>
<p>Pour exercer ces droits ou pour toute question sur le traitement de vos donn&eacute;es dans ce dispositif, vous pouvez contacter Marie-Luce Beraudo &agrave; l'adresse e-mail suivante : contact @ seoalternative . fr</p>
<p>Si vous estimez, apr&egrave;s nous avoir contact&eacute;s, que vos droits &laquo;&nbsp;Informatique et Libert&eacute;s&nbsp;&raquo; ne sont pas respect&eacute;s, vous pouvez adresser une r&eacute;clamation &agrave; la CNIL.</p>
<h2 className="post-title">Information sur les cookies</h2>
<p>Le site seoalternative.fr utilise Google Analytics en tant qu'outil d'analyse d'audience.</p>
<p>Cet outil d&eacute;pose un cookie sur votre navigateur d&egrave;s lors que vous avez cliqu&eacute; sur le bouton "J'accepte" du bandeau de demande de consentement situ&eacute; dans le pied de page lorsque vous arrivez sur le site seoalternative.fr</p>
<p>Les informations personnelles recueillies par Google Analytics sont g&eacute;n&eacute;ralement votre adresse IP, des informations sur votre localisation ou encore des informations sur l'appareil utilis&eacute; pour acc&eacute;der au site seoalternative.fr.</p>
<p>Les informations obtenues via Google Analytics nous permettent de mieux conna&icirc;tre le comportement de nos utilisateurs et ainsi de leur fournir un meilleur service et une meilleur exp&eacute;rience utilisateur.</p>
<p>Par d&eacute;faut, Google Analytics est d&eacute;sactiv&eacute; lorsque vous arrivez sur le site seoalternative.fr. Si vous ne cliquez pas sur le bandeau de demande de consentement ou que vous cliquez sur le bouton "Je refuse", aucun cookie ne sera d&eacute;pos&eacute; sur votre navigateur.</p>
<h2 className="post-title">Crédits</h2>
<p>Le site Web seoalternative.fr a été réalisé avec l'outil Gatsby, thème Arlo développé par Rainbow Themes</p>
<p>Les images qui illustrent le site Web seoalternative.fr, y compris l'icône présente dans le logo, ont été téléchargées sur le site <a href="https://thenounproject.com/" target="_blank" rel="noopener noreferrer">thenounproject.com</a>. Merci à :</p>
<ul>
    <li>Yandi kiem lie, US from Noun Project</li>
    <li>Alice Design from Noun Project</li>
    <li>Bartama Graphic from Noun Project</li>
    <li>H Alberto Gongora from Noun Project</li>
</ul>
<p>La photo de Marie-Luce Beraudo visible sur le site Web seoalternative.fr a été prise au studio <a href="http://www.vicgallery.com/" rel="noopener noreferrer" target="_blank">Un monde ailleurs</a> situé à Saint-Genis-Pouilly. </p>
<p>Partenaire : <a href="https://www.gralon.net/annuaire/internet-et-webmaster/creation-site-internet/communication-et-marketing.htm" target="_blank" rel="noopener noreferrer"><img src="https://logo.gralon.net/plogo-gralon-197574.gif" border="0" alt="Communication et Marketing" /></a></p>
                        </div>
                        <div className="post-content">


                        </div>


                    </div>
             
                </div>

            </div>

        </div>

    )
}
export default Content;